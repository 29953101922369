<template>
  <vuestic-widget
    :hide='true'
    :button="button"
    v-on:adicionarItem="$emit('adicionarItem')"
    :headerText="headerText"
    :startOfShow="startOfShow"
    v-if="listaDeIds"
  >
    <div class="mb-2"></div>
    <data-tables
      :data="filterObjetos"
      :table-props="tableProps"
      :action-col="actionCol"
    >
      <el-table-column
        :prop="nome"
        :label="label"
        sortable="custom"
      />
    </data-tables>

  </vuestic-widget>
</template>

<script>
export default {
  props: {
    listaDeIds: {
      type: Array,
    },
    nome: {
      type: String,
    },
    label: {
      type: String,
    },
    headerText: {
      type: String,
    },
    identificador: {
      type: String,
      required: true,
    },
    objetos: {
      type: Array,
      required: true,
    },
    button: {
      type: Boolean,
      default: false,
    },
    startOfShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableProps: {
        stripe: true,
      },
      actionCol: {
        label: 'EXCLUIR',
        props: {
          width: '220',
          align: 'center',
        },
        buttons: [
          {
            props: {
              type: 'danger',
              icon: 'el-icon-delete',
              circle: true,
            },
            handler: (row) => {
              const { identificador } = this;
              const index = this.filterObjetos.indexOf(row);
              this.$emit('indexRemovido', { identificador, index });
            },
          },
        ],
      },
    };
  },
  computed: {
    filterObjetos() {
      return this.objetos.filter(o => this.listaDeIds.indexOf(o.id) !== -1);
    },
  },
};
</script>
