<template>
  <div>
    <vuestic-widget>
      <form
        @submit.prevent="editarFuncionarioEUser()"
        v-if="funcionario"
      >
        <div
          class="vuestic-alert alert with-close alert-success flex md12"
          v-if="request.ok"
        >
          Os dados do funcionário foram alterados.
        </div>
        <div
          class="vuestic-alert alert with-close alert-danger flex md12"
          v-if="request.fail"
        >
          Ocorreu algum erro ao tentar salvar suas alterações.
        </div>
        <div class="va-row">
          <div class="flex md6 xs12">
            <div class="container style-no-padding-left">
              <div class="row">
                <div class="form-group col-md-8">
                  <div class="input-group">
                    <input
                      id="Username"
                      v-model="user.username"
                      required
                      :disabled="!isActive"
                    />
                    <label
                      class="control-label"
                      for="nome"
                    >
                      Username
                    </label>
                  </div>
                </div>

                <el-checkbox
                  class="col-md-4 mt-1"
                  v-model="isSupervisor"
                  label="Supervisor"
                  border
                />
              </div>
            </div>

            <div class="form-group">
              <div class="input-group">
                <the-mask
                  :disabled="!isActive"
                  id="cpf"
                  :mask="['###.###.###-##']"
                  v-model="funcionario.cpf"
                  required
                />
                <label
                  class="control-label"
                  for="cpf"
                >
                  CPF
                </label>

              </div>
            </div>
            <div>
              <label for="Ativo">
                <el-switch
                  v-model="user.is_active"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                />
                Ativo
              </label>
            </div>
          </div>
          <div class="flex md6 xs12 ">
            <div class="form-group">
              <div class="input-group">
                <input
                  id="nome"
                  :disabled="!isActive"
                  v-model="funcionario.nome"
                  required
                />

                <label
                  class="control-label"
                  for="nome"
                >
                  Nome
                </label>

              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <input
                  :disabled="!isActive"
                  id="email"
                  v-model="user.email"
                  required
                >
                <label
                  class="control-label"
                  for="nome"
                >
                  Email
                </label>
              </div>
            </div>
          </div>
        </div>
        <el-row
          justify="end"
          type="flex"
        >
          <button
            class="btn btn-pale btn-micro mr-2  ml-auto"
            @click.prevent="$router.push({name: 'lista-funcionarios'})"
          >
            Voltar </button>
          <button
            type="submit"
            class="btn btn-primary btn-micro mr-2"
          >Confirmar</button>
        </el-row>
      </form>
    </vuestic-widget>

    <tableInWidget
      :button="true"
      identificador="empresas"
      :listaDeIds="funcionario.empresas"
      headerText="Empresas"
      nome="nome"
      label="NOME"
      :objetos="empresas"
      v-on:indexRemovido="removeElemento"
      v-on:adicionarItem="$refs.modalEmpresa.open()"
    />

    <tableInWidget
      :button="true"
      identificador="areas"
      :listaDeIds="funcionario.areas"
      headerText="Áreas"
      nome="nome"
      label="NOME"
      :objetos="areas"
      v-on:indexRemovido="removeElemento"
      v-on:adicionarItem="$refs.modal.open()"
    />

    <!-- ADD AREAS -->
    <modal
      v-bind:small="true"
      v-bind:force="true"
      ref="modal"
      :cancelClass="'none'"
      v-on:ok="addArea"
    >
      <div slot="title">Adicionar nova área</div>
      <div>
        <multiselect
          v-model="area"
          placeholder="Áreas"
          label="nome"
          selectLabel="Selecione uma área"
          track-by="id"
          :options="areasNaoVinculadas"
        >
          <span slot="noResult">Área não encontrada.</span>
        </multiselect>
      </div>
    </modal>

    <!-- ADD EMPRESA -->
    <modal
      v-bind:small="true"
      v-bind:force="true"
      ref="modalEmpresa"
      :cancelClass="'none'"
      v-on:ok="addEmpresa"
    >
      <div slot="title">Adicionar uma empresa</div>
      <div>
        <multiselect
          v-model="empresa"
          placeholder="Empresas"
          label="nome"
          selectLabel="Selecione uma empresa"
          track-by="id"
          :options="empresasNaoVinculadas"
        >
          <span slot="noResult">Empresa não encontrada.</span>
        </multiselect>
      </div>
    </modal>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import Modal from '@/vuestic-theme/vuestic-components/vuestic-modal/VuesticModal.vue';
import tableInWidget from '@/components/my-components/widget/tableInWidget.vue';

export default {
  name: 'editar-funcionario',
  components: { Modal, Multiselect, tableInWidget },
  data() {
    return {
      isSupervisor: false,
      area: false,
      areas: [],
      funcionarioCopy: null,
      funcionario: [],
      empresa: null,
      empresas: [],
      user: '',
      userCopy: null,
      request: {
        ok: false,
        fail: false,
      },
    };
  },
  watch: {
    isActive() {
      if (!this.user.is_active) {
        this.funcionario = JSON.parse(JSON.stringify(this.funcionarioCopy));
      }
      this.user.username = !this.user.is_active ? this.userCopy.username : this.user.username;
      this.user.email = !this.user.is_active ? this.userCopy.email : this.user.email;
    },
    isSupervisor(valor) {
      this.user.groups = [];
      if (valor) {
        this.user.groups = [1];
      }
    },

    // eslint-disable-next-line func-names
    'user.groups': function (valor) {
      this.isSupervisor = (valor.indexOf(1) !== -1);
    },
  },
  computed: {
    id() {
      return this.$router.history.current.params.id;
    },
    objetoFuncionario() {
      const { funcionarioCopy, funcionario } = this;
      return funcionarioCopy.cpf === funcionario.cpf
        && funcionarioCopy.nome === funcionario.nome;
    },
    objetoUserFuncionario() {
      const { userCopy, user } = this;
      return userCopy.email === user.email
        && userCopy.is_active === user.is_active
        && userCopy.username === user.username;
    },
    objetoUser() {
      const { userCopy, user } = this;
      return user.groups.length === userCopy.groups.length;
    },
    isActive() {
      return this.user.is_active;
    },
    userSupervisor() {
      return this.user.groups.indexOf(1) !== -1;
    },
    areasNaoVinculadas() {
      return this.areas.filter(a => this.funcionario.areas.indexOf(a.id) === -1);
    },
    empresasNaoVinculadas() {
      return this.empresas.filter(e => this.funcionario.empresas.indexOf(e.id) === -1);
    },
  },

  mounted() {
    this.getEmpresas();
    this.getAreas();
    this.getFuncionarioUser();
  },

  methods: {
    message(type, title) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showCancelButton: false,
        type,
        title,
        showConfirmButton: false,
        timer: 2000,
      });
    },
    addArea() {
      this.editarUser(`/api/funcionario/${this.funcionario.id}.json`, this.funcionario);
      this.funcionario.areas.push(this.area.id);
      this.$refs.modal.close();
      this.area = [];
    },
    addEmpresa() {
      this.editarUser(`/api/funcionario/${this.funcionario.id}.json`, this.funcionario);
      this.funcionario.empresas.push(this.empresa.id);
      this.$refs.modalEmpresa.close();
      this.empresa = [];
    },

    editarFuncionarioEUser() {
      if (!this.objetoFuncionario) {
        this.editarUser(`/api/funcionario/${this.funcionario.id}.json`, this.funcionario);
      }
      if (!this.objetoUserFuncionario) {
        this.editarUser(`/api/funcionario-user/${this.id}.json`, this.user);
      }
      if (!this.objetoUser) {
        this.editarUser(`/api/user/${this.id}.json`, this.user);
      }
      if (this.objetoFuncionario && this.objetoUserFuncionario && this.objetoUser) {
        this.message('info', 'Nenhuma alteração realizada');
      }
    },
    prepararFuncionario(res) {
      this.funcionario = JSON.parse(JSON.stringify(res.data.funcionario));
      this.funcionarioCopy = JSON.parse(JSON.stringify(res.data.funcionario));
    },
    preparUser(usuario) {
      this.prepararFuncionario(usuario);
      this.user = JSON.parse(JSON.stringify(usuario.data));
      this.userCopy = JSON.parse(JSON.stringify(usuario.data));
      this.user.funcionario = {};
      this.userCopy.funcionario = {};
    },
    getFuncionarioUser() {
      this.$axios.get(`/api/funcionario-user/${this.id}.json`)
        .then((res) => {
          this.preparUser(res);
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          alert('Funcionário não encontrado.');
          this.$router.push('/');
        });
    },
    editarUser(url, objeto) {
      this.$axios.patch(url, objeto)
        .then(() => {
          this.getFuncionarioUser();
          this.message('success', 'Funcionario Editado com Sucesso!!!');
          this.request.ok = true;
          this.request.fail = false;
        })
        .catch(() => {
          this.message('error', 'Erro ao Editar Funcionario');
          this.request.ok = false;
          this.request.fail = true;
        });
    },
    getEmpresas() {
      this.$axios.get('/api/empresa/')
        .then((res) => {
          this.empresas = res.data;
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          alert('Empresa não encontrada.');
          this.$router.push('/');
        });
    },

    getAreas() {
      this.$axios.get('/api/area/')
      // eslint-disable-next-line no-return-assign
        .then(res => this.areas = res.data);
    },
    removeElemento(evento) {
      this.funcionario[evento.identificador].splice(evento.index, 1);
      this.editarUser(`/api/funcionario/${this.funcionario.id}.json`, this.funcionario);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.style-no-padding-left {
  padding-left: 0px !important;
}
.el-checkbox.is-bordered {
  border: 1px solid #ccc !important;
}
</style>
